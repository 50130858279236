/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { MouseEvent } from "react";
import { AnyAction } from "redux";
import { actions } from "./actions";
import {
  validations,
  addClientIdsToStory,
  groupValidationErrors,
  opinionPollErrors,
  getUniqueMetaTags,
  annotateStoryWithAuthorInfo,
  getNewChunkedEditorState,
  getDistinctStoryElements,
  getWebsiteLanguage
} from "pages/story-editor/utils";
import { getMetadataWithDimensions } from "utils/image-metadata.utils";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, NOTIFICATION_INFO } from "containers/page/actions";

import { WORKSPACE_TABS_PATH } from "pages/workspace/routes";
import { updatePageReferer } from "pages/workspace/action-creators";

import { replace } from "connected-react-router";
import { isEmptyObject } from "utils/object.utils";

import { t } from "i18n";
import {
  deleteSocialCards,
  getRecommendedTags,
  getEntitiesByType,
  updateStoryStatus,
  restoreStory
} from "../../helpers/api";
import { getOEmbedFromUrl } from "../../api/get-oembed-from-url";
import {
  Story,
  getStory,
  saveStory,
  saveNewStory,
  SaveResponse,
  StoryTemplate as StoryTemplates,
  StoryElement,
  CompositeStoryElement,
  WorkflowActions,
  StoryElementType,
  StoryElementSubType,
  Contribution,
  ChildStoryElement,
  UnsavedStory,
  generatePlagiarismReport,
  StoryWithoutSlug,
  AnyStory,
  StoryStatus,
  StoryEntity,
  StoryTag,
  StoryElementVideoSubtype
} from "api/story";
import { getSocialAccountsAndCards } from "api/social";
import { base64Encode, removeKeyFromObject, getHtmlWordCount, getReduxActionLog, ValidationError } from "utils";
import { fetchTimelineEvents } from "api/activity-log";
import { get, isEmpty, uniqBy } from "lodash";
import { format, isPast } from "date-fns";

import {
  STORY_EDITOR_PATH,
  STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_MANAGE_PATH,
  STORY_EDITOR_ALTERNATIVES_PATH,
  STORY_EDITOR_PUBLISH,
  STORY_EDITOR_CARD_SHARE,
  STORY_EDITOR_CARD_SHARE_IMAGE_PATH,
  STORY_EDITOR_POLL_EDIT,
  STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_PLAGIARISM_REPORT,
  STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH,
  STORY_EDITOR_IMAGE_INSPECTOR_PATH
} from "./routes";
import { navigateFn, route, navigate } from "../../utils/routes.utils";
import generateInspectorDataByRoute from "./components/inspector/inspector-data";
import { StoryTemplate, Fields } from "api/route-data/story-route-data";
import { PartialAppState, VideoOembedSearchPages, InspectorErrors } from "./state";
import { ThunkDispatch } from "redux-thunk";
import {
  addTemplateCards,
  cardShareImageDelete,
  cardShareUpdate,
  deleteStoryEditorCard,
  deleteStorySocialCard,
  initEditorState,
  loadRecommendedTags,
  loadSocialAccountsAndCards,
  addSocialNotificationsHistory,
  loadStoryData,
  removeStoryElementError,
  setImageForInspector,
  setOembedUrl,
  setPublishInspectorValidationErrors,
  setStory,
  setStoryElementError,
  setStoryValidationErrors,
  setTimelineEvent,
  setWorkflowTransitions,
  storyEditorAddStoryElement,
  storyEditorDeleteStoryElement,
  toggleStoryElementLoading,
  updateContributionsAction,
  loadAuthorContributionsAction,
  updateAuthorContributionsAction,
  updateAlternative,
  updateEditorConfig,
  updateImageElement as updateImageElementAction,
  closeStoryRestoreModal,
  resetTimeline,
  fetchOpinionPoll,
  setOpinionPoll,
  updateOpinionPoll,
  updateInspectorData,
  updateOembedUrl,
  updateTemplateFields,
  updateStoryStatusAction,
  setOpinionPollId,
  setOpinionPollInit,
  setOpinionPollSuccess,
  updateStorySearchTerm,
  setStoryList,
  setSelectedStory,
  updateImportCardId,
  importCard,
  resetImportCard,
  toggleStoryCleanUpConfirmation,
  resetStoryEditorState,
  setBanner,
  startSavingStory,
  stopSavingStory,
  storyEditorSplitStoryElement,
  publishStoryInit,
  publishStoryFailure,
  plagiarismCheckInit,
  plagiarismCheckFailure,
  plagiarismCheckSuccess,
  resetImportCardDisplay,
  updateSelectedPlace,
  updateOpinionPollImageUploadStatus,
  updateStoryAttribute,
  updateEntitiesStoryAttribute,
  updateStoryElementEntitiesAction,
  updateStoryTag,
  videoOEmbedSearchTermChange,
  videoOEmbedSearchSuccess,
  videoOEmbedSearchReset,
  setIsStoryModifiedState,
  setStoryTransitionStatus,
  updateStory,
  reapplyEditorState,
  updateEmbargo,
  deleteDefaultCard,
  setEditorState,
  setTextParaphrasingStatus,
  setSelectedHeroImage
} from "./action-creators";

import { StoryId, CardId, StoryElementId, PolltypeId, ClientId, ImageId } from "api/primitive-types";
import { Image, AnyImage, ImageOrNewImage } from "api/search-media-image";
import { StoryElementDirection } from "./operations/story-elements/add";
import { OembedResponse } from "api/get-oembed-from-url";
import newStoryElement from "./data/story-elements";
import { contributionsToUnSavedContributions, authorsToContributions } from "./utils";
import { appendTextStoryElementToComposite, addImagesToImageGalleryElement } from "./data/composite-story-element";
import { getPollDetails, savePoll, createPoll } from "api/polls";
import { TimelineEvent } from "api/activity-log";
import { newOpinionPoll } from "./data/opinion-poll";
import { fetchStoryForImport } from "api/search";
import { convertImportDataToNew } from "./data/import-card";
import { MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT } from "pages/media-library/actions";
import { clearMediaForMultiSelectAction } from "pages/media-library/action-creators";
import { isUrl, isId } from "./story-element-types/utils";
import { Node, Schema } from "prosemirror-model";
import pDebounce from "p-debounce";
import { getElementIdFromClientId } from "./utils";
import uploadImage from "./components/opinion-poll-inspector/poll-image-upload";
import { search as pralineSearch, getOEmbed, Video as PralineVideo } from "api/praline";
import { INITIAL_VIDEO_OEMBED_SEARCH_PAGES } from "pages/story-editor/reducers/initial-state";

import { makeEditorState } from "./prosemirror/prosemirror";
import { Entity } from "api/entity";
import { getJsembedElement } from "./story-element-types/oembed/utils";
import { isHTTPError } from "api/errors";
import { notificationError } from "action-creators/notification";
import { getErrorInfo, ErrorCodes } from "utils/error.utils";
import { ItsmanWindow } from "containers/page/page";
import { AllowedStoryFields, getSuggestion, paraphraseText } from "api/ai";
import { EditorState } from "prosemirror-state";
import { schema } from "./prosemirror/schema";
import { Publisher } from "pages/settings/pages/integrations/state";
import { findElementWithClientIdNP, findFirstEditableCursorPosition } from "./operations/find";
import { VideoSignature } from "api/video-sign";
import { selectElementText, setTextSelection } from "pages/story-editor/operations/selection";
const fetchStoryForImportDebouce = pDebounce(fetchStoryForImport, 250);
const pralineSearchDebounced = pDebounce(pralineSearch, 250);
const w = window as ItsmanWindow;

export function generateUntitledHeadline() {
  return "Untitled " + format(new Date(), "MMM dd, yyyy hh:mm aaa");
}

export const updateUIStorySaveError = (dispatch: ThunkDispatch<any, any, any>, message: string) => {
  dispatch(
    setBannerAction({
      message: message,
      closable: true,
      type: "error",
      timestamp: format(new Date(), "MMM dd, yyyy hh:mm aa")
    })
  );
  dispatch(stopSavingStory());
};

export const handleSaveStoryError = (dispatch: ThunkDispatch<any, any, any>, error: Error, story: any) => {
  const errorInfo = handleUnexpectedError(dispatch, error);

  try {
    if (errorInfo) {
      const { errors } = errorInfo.error;

      if (errors) {
        const groupedErrors = groupValidationErrors(errors);

        dispatch(setIsStoryModifiedState(true));
        if (story["story-template"] === "live-blog" && errors) {
          dispatch(stopSavingStory());
          if (groupedErrors.editor.cards) {
            dispatch(setStoryValidationErrors(groupedErrors));
            return dispatch({
              type: NOTIFICATION_ERROR,
              payload: { message: t("story-editor.errors_in_cards"), action: null }
            });
          }
        }
      }

      if (isHTTPError(error)) {
        if (error.status === 500 || error.status === 400) {
          return updateUIStorySaveError(dispatch, t("story-editor.story_save_error"));
        }
        if (error.status === 403) {
          dispatch(stopSavingStory());
          return dispatch(notificationError(t("story-editor.invalid_session_error")));
        }
        error.message && updateUIStorySaveError(dispatch, error.message);
      } else {
        updateUIStorySaveError(dispatch, t("story-editor.story_save_timeout"));
      }
    } else {
      dispatch(stopSavingStory());
    }
  } catch (e) {
    dispatch(stopSavingStory());
    w.newrelic.noticeError(e, {
      errorType: "handle_save_error",
      errorDescription: "Error while handling save error",
      errorInfo: JSON.stringify({ msg: error.message }),
      reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
    });
  }
};

const checkDuplicateStoryElements = (story: Story) => {
  for (const cardId in story.cards) {
    const tree = story.cards[cardId].tree;
    getDistinctStoryElements(tree);
  }
};

export const handleSaveStorySuccess = (
  dispatch: ThunkDispatch<any, any, any>,
  state: PartialAppState,
  response: SaveResponse
) => {
  const { story: newStory, "workflow-transitions": workflowTransitions } = response;
  checkDuplicateStoryElements(newStory);
  dispatch(setStory(newStory, workflowTransitions));
  loadAuthorContributions(dispatch, state, newStory);
  dispatch(stopSavingStory());
  return newStory;
};
export const sanitizeFileName = (name: string) => {
  let updatedName = name
    .replace(/[!@#$%^&*()_+=[\]{}|\\:;"'<>,/?&~`]/g, "")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/\.+/g, ".")
    .replace(/^\.+$/, "");

  updatedName = encodeURIComponent(updatedName);
  return updatedName;
};

export const handleUnexpectedError = (dispatch: ThunkDispatch<any, any, any>, error: Error) => {
  // TODO: refactor this error handling logic
  dispatch(setStoryTransitionStatus(false));
  const errorInfo = getErrorInfo(error);
  if (errorInfo.errorCode === ErrorCodes.UnexpectedError) {
    dispatch(notificationError(t("common.phrases.unexpected-error")));
    return;
  } else {
    return errorInfo;
  }
};

export const handleInvalidScheduleCancelError = (
  storyId: StoryId,
  dispatch: ThunkDispatch<any, any, any>,
  error: Error
) => {
  const errorInfo = handleUnexpectedError(dispatch, error);

  if (!errorInfo) return;

  if (errorInfo.errorCode === ErrorCodes.InvalidScheduleCancel) {
    dispatch(notificationError(t("story-editor.invalid-schedule-cancel")));
    dispatch(getStoryAction(storyId, {}));
    return;
  }
  return errorInfo;
};

export const getTemplateValueAction = (template: string, storyTemplates: StoryTemplate[]): StoryTemplate | null =>
  storyTemplates.find((temp) => temp.slug === template) || null;

export const getTemplateFieldsAction = (storyTemplate: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const templateFields = getState().config.templateFields;
  if (!isEmpty(templateFields)) {
    const storyTemplateFields = templateFields.story[storyTemplate] || templateFields.story.all;
    dispatch(updateTemplateFields(storyTemplateFields.config.fields));
  }
};

const setEditorConfigAction = (storyTemplate: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const allTemplates = getState().config.storyTemplates;
  // TODO: Don't return template as null from server 🙃
  const properStoryTemplate = storyTemplate === null ? StoryTemplates.Text : storyTemplate;
  const action = getTemplateValueAction(properStoryTemplate, allTemplates);
  const editorConfig = action && action["editor-config"];
  if (editorConfig) {
    dispatch(updateEditorConfig(editorConfig));
  }
};

export const setTemplateAction = (template: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setEditorConfigAction(template));
  dispatch(getTemplateFieldsAction(template));
};

export const onChangeOfTemplateAction = (template: StoryTemplate, storyId: StoryId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const navigate = navigateFn(dispatch);
  const state = getState();
  const story = state.storyEditor.story;
  let editorState;
  const { currentCardLoading, cardsLoaded, numberOfCardsShown } = state.storyEditor.ui;
  let newCurrentCard = currentCardLoading;
  let newCardsLoaded = cardsLoaded;
  if (storyId === "new") {
    newCardsLoaded = [];
    dispatch(deleteDefaultCard());
  }

  if (numberOfCardsShown) {
    newCurrentCard = null;
    newCardsLoaded = story.tree.slice(0, numberOfCardsShown).map((tree) => tree["content-id"]);
  }
  if (template.slug === "live-blog") {
    const numberOfCardsToLoad = state.config.numberOfCardsToLoad;
    dispatch({ type: actions.UPDATE_TEMPLATE, payload: { template: template.slug, numberOfCardsToLoad } });

    const newTree = story.tree.slice(0, numberOfCardsToLoad);

    const chunkedStory = {
      ...story,
      tree: newTree
    };

    editorState = makeEditorState(chunkedStory as Story, {});
    dispatch({
      type: actions.SET_EDITOR_STATE,
      payload: {
        editorState: editorState,
        skipStoryUpdate: true,
        numberOfCardsShown: newTree.length,
        cardsLoaded: newCardsLoaded
      }
    });
  } else {
    editorState = getNewChunkedEditorState(story as Story, newCardsLoaded, newCurrentCard);
    dispatch({ type: actions.UPDATE_TEMPLATE, payload: { template: template.slug } });
    dispatch({
      type: actions.SET_EDITOR_STATE,
      payload: { editorState: editorState, cardsLoaded: newCardsLoaded }
    });
  }

  dispatch(
    setStoryValidationErrors({
      inspector: {},
      editor: {}
    })
  );
  navigate(STORY_EDITOR_PATH, { id: storyId });

  dispatch({ type: actions.UPDATE_EDITOR_CONFIG, payload: { editorConfig: template["editor-config"] } });
  dispatch(getTemplateFieldsAction(template.slug));
  if (storyId === "new") {
    dispatch(addTemplateCards());
  }
};

export const loadAuthorContributions = (
  dispatch: ThunkDispatch<any, any, any>,
  state: PartialAppState,
  story: Story
) => {
  if (state.features.isContributorRolesEnabled) {
    const authors =
      story.authors && story.authors.length
        ? story.authors
        : [{ id: state.config.member.id, name: state.config.member.name }];
    const contributorRoles = state.config.contributorRoles;
    dispatch(loadAuthorContributionsAction(authorsToContributions(authors, contributorRoles)));
  }
};

export const getStoryAction = (storyId: StoryId, params: { versionId?: StoryId }, loadAllElements?: boolean) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const response = await getStory(storyId, params);
  const state = getState();
  const numberOfCardsToLoad = state.config.numberOfCardsToLoad;
  const storyTemplate = response.story["story-template"];
  const story = response.story.contributors
    ? { ...response.story, contributors: contributionsToUnSavedContributions(response.story.contributors) }
    : { ...response.story, contributors: [] };

  loadAuthorContributions(dispatch, state, story);
  if (storyTemplate === "live-blog") {
    dispatch(loadStory(story, { numberOfCardsToLoad }));
  } else {
    dispatch(loadStory(story, { loadAllElements }));
  }
  dispatch(updateContributionsAction(response.story.contributors || []));
  dispatch(setWorkflowTransitions(response["workflow-transitions"]));
  dispatch(setTemplateAction(storyTemplate));

  const { "social-accounts": socialAccounts, "social-cards": socialCards } = await getSocialAccountsAndCards(storyId);
  dispatch(loadSocialAccountsAndCards(socialAccounts, socialCards));
};

export const addSocialNotificationsHistoryAction = (storyId: StoryId) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  const { "social-accounts": socialAccounts, "social-cards": socialCards } = await getSocialAccountsAndCards(storyId);
  const socialCardsHistory = [...socialCards];
  dispatch(addSocialNotificationsHistory(socialAccounts, socialCards, socialCardsHistory));
};

export const updateStoryContributions = (updatedStoryContributions: Contribution[]) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  const unsavedContributions = contributionsToUnSavedContributions(updatedStoryContributions);
  dispatch(updateContributionsAction(updatedStoryContributions));
  dispatch({ type: actions.UPDATE_STORY, payload: { key: "contributors", value: unsavedContributions } });
};

export const updateStoryAuthorContributions = (updatedStoryAuthorContributions: Contribution[]) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  dispatch(updateAuthorContributionsAction(updatedStoryAuthorContributions));
};

export const generateMetaKeywords = (keywords: string[]): Array<{ label: string; value: string } | null> => {
  if (keywords && keywords.length) {
    return keywords.map((keyword) => {
      if (keyword === "") {
        return null;
      } else {
        return {
          label: keyword,
          value: keyword
        };
      }
    });
  }
  return [];
};

export const filterMandatoryFieldsAction = (
  fields: Array<{ specPath: string; storyPath: string; Component: React.Component }>,
  fieldSpecs: Fields,
  errors: InspectorErrors
) => {
  const mandatoryFields = fields.filter(
    (field) => !validations.isHidden(field.specPath, fieldSpecs) && validations.isMandatory(field.specPath, fieldSpecs)
  );
  const validationErrorFields = fields.filter((field) => get(errors, field.specPath, false));
  return uniqBy([...mandatoryFields, ...validationErrorFields], "specPath");
};

export const deleteSocialCardAction = (cardId: CardId, contentId: CardId) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  deleteSocialCards(cardId, contentId).then(() => dispatch(deleteStorySocialCard(cardId)));
};

export enum EMBEDS {
  VOD_VIDEO = "vod-video",
  BITGRAVITY_VIDEO = "bitgravity-video",
  BRIGHTCOVE_VIDEO = "brightcove-video",
  SOUNDCLOUD_AUDIO = "soundcloud-audio",
  JWPLAYER = "jwplayer",
  DILMOT_Q_AND_A = "dilmot-q-and-a",
  YOUTUBE_VIDEO = "youtube-video",
  DAILYMOTION_VIDEO = "dailymotion-video",
  DAILYMOTION_EMBED_SCRIPT = "dailymotion-embed-script",
  INSTAGRAM = "instagram",
  FACEBOOK_POST = "facebook-post",
  FACEBOOK_VIDEO = "facebook-video",
  TWITTER = "twitter",
  X = "x",
  VIMEO_VIDEO = "vimeo-video",
  JSEMBED = "jsembed",
  VIDIBLE_VIDEO = "vidible-video",
  BUZZSPROUT = "buzzsprout-podcast",
  TIKTOK = "tiktok"
}

const EMBED_URL_PATTERN_MAP: Map<EMBEDS, RegExp> = new Map([
  [EMBEDS.VOD_VIDEO, /.*vod-platform\.net.*/],
  [EMBEDS.BITGRAVITY_VIDEO, /.*cdn.bitgravity\.com.*/],
  [EMBEDS.BRIGHTCOVE_VIDEO, /.*players\.brightcove\.net.*/],
  [EMBEDS.SOUNDCLOUD_AUDIO, /.*soundcloud\.com.*/],
  [EMBEDS.JWPLAYER, /.*(content\.jwplatform\.com|cdn\.jwplayer\.com).*/],
  [EMBEDS.DILMOT_Q_AND_A, /.*dilmot\.com.*/],
  [EMBEDS.YOUTUBE_VIDEO, /.*(youtube.*|.*youtu\.be).*/],
  [EMBEDS.DAILYMOTION_VIDEO, /.*dailymotion.*/],
  [EMBEDS.INSTAGRAM, /.*instagram.*/],
  [EMBEDS.FACEBOOK_POST, /.*facebook\.com.*posts.*/],
  [EMBEDS.FACEBOOK_VIDEO, /.*(facebook\.com.*watch|facebook\.com.*videos|fb\.watch|fb\..*\/v\/|facebook\.com\/reel).*/],
  [EMBEDS.TWITTER, /.*twitter\.com.*/],
  [EMBEDS.X, /.*x\.com.*/],
  [EMBEDS.VIMEO_VIDEO, /.*vimeo\.com.*/],
  [EMBEDS.BUZZSPROUT, /.*buzzsprout\.com.*/],
  [EMBEDS.TIKTOK, /.*tiktok\.com.*/]
]);

export const guessEmbedProvider = (oEmbedURL: string): EMBEDS => {
  const embedProviders = Array.from(EMBED_URL_PATTERN_MAP.keys());
  for (const provider of embedProviders) {
    const regex = EMBED_URL_PATTERN_MAP.get(provider);
    if (regex && oEmbedURL.match(regex)) {
      return provider;
    }
  }
  return EMBEDS.JSEMBED;
};

export const fetchSocialEmbed = (storyElementID: StoryElementId, embedCode?: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const oEmbedURL = get(getState().storyEditor.ui.storyElements, [storyElementID, "url"], null);

  if (embedCode) {
    const changes = getJsembedElement(embedCode);
    dispatch(setOembedUrl(storyElementID, changes));
    return;
  }

  if (!oEmbedURL) {
    return null;
  }

  const guessedProvider = isId(oEmbedURL) ? EMBEDS.VIDIBLE_VIDEO : guessEmbedProvider(oEmbedURL);

  if (guessedProvider === EMBEDS.JSEMBED) {
    const changes = {
      "embed-js": base64Encode(oEmbedURL),
      type: EMBEDS.JSEMBED
    };

    dispatch(setOembedUrl(storyElementID, changes));
  } else if (guessedProvider === EMBEDS.SOUNDCLOUD_AUDIO) {
    const embedUrl =
      "https://w.soundcloud.com/player/?url=" +
      encodeURIComponent(oEmbedURL) +
      "&amp;auto_play=false&amp;hide_related=true&amp;show_comments=false&amp;" +
      "show_user=false&amp;show_reposts=false&amp;visual=true";

    const changes = { "embed-url": embedUrl, url: oEmbedURL, type: EMBEDS.SOUNDCLOUD_AUDIO };
    dispatch(setOembedUrl(storyElementID, changes));
  } else if (guessedProvider === EMBEDS.JWPLAYER) {
    const JWPLAYER_EMBED_REGEX = /(players|videos)\/(.+?)-(.+?)\./;
    const match = JWPLAYER_EMBED_REGEX.exec(oEmbedURL);

    if (match) {
      const [matched, , videoId, playerId] = match,
        videoUrl = `//content.jwplatform.com/videos/${videoId}.mp4`,
        thumbnailUrl = `//content.jwplatform.com/thumbs/${videoId}.jpg`,
        playerUrl = `//content.jwplatform.com/${matched}html`,
        metadata = {
          "video-id": videoId,
          "player-id": playerId,
          "video-url": videoUrl,
          "thumbnail-url": thumbnailUrl,
          "player-url": playerUrl,
          "embed-code": base64Encode(oEmbedURL)
        },
        url = videoUrl,
        changes = {
          metadata,
          url,
          "file-type": "video",
          "content-type": "video/jwplayer",
          subtype: EMBEDS.JWPLAYER,
          type: "external-file"
        };
      dispatch(setOembedUrl(storyElementID, changes));
    }
  } else if (guessedProvider === EMBEDS.BRIGHTCOVE_VIDEO) {
    const BRIGHTCOVE_EMBED_REGEX = /players\.brightcove\.net\/(.*?)\/(.*?)_(.*?)\/.*videoId=(\d+)/;
    const match = BRIGHTCOVE_EMBED_REGEX.exec(oEmbedURL);
    if (match) {
      const [link, accountId, playerId, playerMedia, videoId] = match;
      const metadata = {
        "account-id": accountId,
        "player-id": playerId,
        "player-media": playerMedia,
        "video-id": videoId,
        "embed-code": base64Encode(oEmbedURL),
        "player-url": "//" + link
      };
      const changes = {
        metadata,
        subtype: EMBEDS.BRIGHTCOVE_VIDEO,
        type: "external-file",
        "file-type": "video",
        "content-type": "video/brightcove"
      };
      dispatch(setOembedUrl(storyElementID, changes));
    }
  } else if (guessedProvider === EMBEDS.VOD_VIDEO) {
    const VOD_EMBED_REGEX = /vod-platform\.net\/Embed\/(\w+)/;
    const match = VOD_EMBED_REGEX.exec(oEmbedURL);
    const link = match && match[0];
    const videoId = match && match[1];
    const metadata = {
      "video-id": videoId,
      "embed-code": base64Encode(oEmbedURL),
      "video-url": `//${link}`
    };
    const changes = { metadata, subtype: EMBEDS.VOD_VIDEO, type: "external-file", "embed-js": base64Encode(oEmbedURL) };
    dispatch(setOembedUrl(storyElementID, changes));
  } else if (guessedProvider === EMBEDS.DILMOT_Q_AND_A) {
    const INVALID_DILMOT_CONFIG = { "dilmot-account": null, "dilmot-id": null };
    const DILMOT_LINK_REGEX = /src="([^"]*)"/i;
    const DILMOT_URL_REGEX = /https?:\/\/(.*).dilmot.com\/streams\/(.*)\/embed/i;

    const urlMatch = DILMOT_LINK_REGEX.exec(oEmbedURL);
    if (urlMatch) {
      const url = urlMatch[1];

      const parsedUrl = DILMOT_URL_REGEX.exec(url);
      const metadata = parsedUrl
        ? { "dilmot-account": parsedUrl[1], "dilmot-id": parsedUrl[2] }
        : INVALID_DILMOT_CONFIG;

      const changes = {
        metadata,
        subtype: EMBEDS.DILMOT_Q_AND_A,
        type: EMBEDS.JSEMBED,
        "embed-js": base64Encode(oEmbedURL)
      };
      dispatch(setOembedUrl(storyElementID, changes));
    }
  } else if (guessedProvider === EMBEDS.BITGRAVITY_VIDEO) {
    const changes = {
      subtype: EMBEDS.BITGRAVITY_VIDEO,
      type: "external-file",
      "file-type": "video",
      "content-type": "video/vnd.bitgravity",
      url: oEmbedURL
    };
    dispatch(setOembedUrl(storyElementID, changes));
  } else {
    dispatch(toggleStoryElementLoading(storyElementID));

    const params = { "oembed-url": oEmbedURL, provider: guessedProvider };

    getOEmbedFromUrl(params)
      .then((data: OembedResponse) => {
        const { html, ...restOfData } = data;
        dispatch(toggleStoryElementLoading(storyElementID));
        dispatch(setOembedUrl(storyElementID, { ...restOfData, "embed-js": base64Encode(data.html) }));
      })
      .catch((error: any) => {
        dispatch(toggleStoryElementLoading(storyElementID));
        dispatch(setStoryElementError(storyElementID, JSON.parse(error.message).error));
      });
  }
  return;
};

export const fetchVidibleEmbed = (storyElementID: StoryElementId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const vidibleId = get(getState().storyEditor.ui.storyElements, [storyElementID, "url"], null);

  if (!vidibleId) {
    return null;
  }
  dispatch(toggleStoryElementLoading(storyElementID));

  const params = { "oembed-url": vidibleId, provider: EMBEDS.VIDIBLE_VIDEO };

  getOEmbedFromUrl(params)
    .then((data: OembedResponse) => {
      dispatch(toggleStoryElementLoading(storyElementID));
      dispatch(setOembedUrl(storyElementID, { ...data, "embed-js": base64Encode(data.html) }));
    })
    .catch((error: { message: string; type: string }) => {
      dispatch(toggleStoryElementLoading(storyElementID));
      dispatch(setStoryElementError(storyElementID, JSON.parse(error.message).error));
    });
  return;
};

export const fetchVideoClipEmbed = (
  storyElementID: StoryElementId,
  publisher: Publisher,
  videoHost: string,
  response: VideoSignature
) => (dispatch: ThunkDispatch<any, any, any>) => {
  const playbackUrl = get(response, ["output", "playback_url"]);
  const publisherNameWithVideoInfo = `${publisher["name"]}/video/${playbackUrl.substring(24)}`;
  const url = `${videoHost}${publisherNameWithVideoInfo}`;
  const changes = {
    type: StoryElementType.Video,
    metadata: {
      "video-clip-url": url,
      "video-id": get(response, ["asset_id"]),
      "thumbnail-url": get(response, ["output", "thumbnail_url", "[0]"]),
      "publisher-id": publisher["id"]
    },
    provider: "gumlet",
    subtype: StoryElementVideoSubtype.VideoClip,
    "video-s3-key": publisherNameWithVideoInfo,
    "external-video-id": get(response, ["asset_id"])
  };

  dispatch(toggleStoryElementLoading(storyElementID));
  dispatch(setOembedUrl(storyElementID, changes));
  dispatch(setIsStoryModifiedState(true));
  return;
};

export const updateSocialEmbed = (id: StoryElementId, url: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  if (url === "") {
    dispatch(updateOembedUrl(id, ""));
    dispatch(setOembedUrl(id, { type: EMBEDS.JSEMBED }));
    dispatch(removeStoryElementError(id));
    return;
  }

  if (get(getState().storyEditor.ui.storyElements, [id, "error"], null)) {
    dispatch(removeStoryElementError(id));
  }

  if (isUrl(url)) {
    dispatch(updateOembedUrl(id, url.trim()));
  } else if (isId(url)) {
    dispatch(updateOembedUrl(id, url));
  } else {
    dispatch(fetchSocialEmbed(id, url));
    dispatch(setStoryElementError(id, { hint: t("story-editor.story-element.js-embed-warning") }));
  }
};

export const replaceUploadedVideo = (id: StoryElementId) => (dispatch: ThunkDispatch<any, any, any>) => {
  const changes = {
    type: StoryElementType.Video,
    metadata: {},
    provider: "",
    subtype: StoryElementVideoSubtype.VideoClip,
    "video-s3-key": "",
    url: "",
    "external-video-id": ""
  };

  dispatch(setOembedUrl(id, changes));
  dispatch(removeStoryElementError(id));
  return;
};

export const loadMoreCardsAction = (story: Story, numberOfCardsShown: number, numberOfCardsToLoad: number) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const numberOfCardsToLoadMore = numberOfCardsToLoad + numberOfCardsShown;
  const newTree = story.tree.slice(0, numberOfCardsToLoadMore);
  const cardsLoaded = newTree.map((card) => card["content-id"]);
  const chunkedStory = {
    ...story,
    tree: newTree
  };

  const newEditorState = makeEditorState(chunkedStory as Story, {});
  dispatch({
    type: actions.SET_EDITOR_STATE,
    payload: { editorState: newEditorState, skipStoryUpdate: true, numberOfCardsShown: newTree.length, cardsLoaded }
  });
};

export function loadMoreStoryElementsAction() {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
    const state = getState();
    const { story, ui } = state.storyEditor;
    const { cardsLoaded, currentCardLoading, cardsToLoad } = ui;

    let newCard;
    let newCardsLoaded = cardsLoaded;
    let newCardsToLoad = cardsToLoad;
    if (currentCardLoading) {
      let newElements = currentCardLoading.elements.slice(1);
      if (newElements.length) {
        newCard = { card: currentCardLoading.card, elements: newElements };
      } else {
        newCard = null;
        newCardsLoaded = [...cardsLoaded, currentCardLoading.card];
      }
    } else if (cardsToLoad && cardsToLoad.length) {
      newCardsToLoad = cardsToLoad.slice(1);
      let newCardId = cardsToLoad[0];
      newCard = { card: newCardId, elements: story.cards[newCardId].tree.slice(1) };
    } else {
      newCard = null;
    }

    const editorState = getNewChunkedEditorState(story as Story, newCardsLoaded, newCard);

    dispatch({
      type: actions.SET_EDITOR_STATE,
      payload: {
        editorState: editorState,
        skipStoryUpdate: true,
        cardsLoaded: newCardsLoaded,
        currentCardLoading: newCard,
        cardsToLoad: newCardsToLoad
      }
    });
  };
}

export const loadStory = (story: UnsavedStory, opts: { [key: string]: any }) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const storyWithTemplate = { ...story, "story-template": story["story-template"] || "text" };
  const storyWithClientIds = addClientIdsToStory(storyWithTemplate);
  dispatch(loadStoryData(storyWithClientIds));
  dispatch(initEditorState(storyWithClientIds, opts));
};

export const getRecommendedTagsAction = (storyId: StoryElementId) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    getRecommendedTags(storyId).then((tags) => dispatch(loadRecommendedTags(tags)));
  };
};

export const getAttributesOfTypeEntityOptionsAction = (query: string, entityTypes: any) =>
  getEntitiesByType({ query, entityTypes });

export const updateAttributeAction = (key: string, value: Array<Entity | string>, type?: string) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateStoryAttribute(key, value));
  type === "entity" && dispatch(updateEntitiesStoryAttribute(key, value as Array<Entity>));
};

export const updateStoryElementEntities = (storyElementId: StoryElementId, entity: Entity | null) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateStoryElementEntitiesAction(storyElementId, entity));
};

export const updateStoryTagAction = (value: Array<StoryTag | StoryEntity>) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const metaKeywords = getState().storyEditor.story.seo["meta-keywords"];
  const updatedKeywords = getState().features.isCopyTagsToMetaKeywordsEnabled
    ? getUniqueMetaTags(value, getState().storyEditor.story.seo["meta-keywords"])
    : metaKeywords;
  const tagEntities = value.filter((tag) => tag["tag-type"] === "Entity");
  dispatch(updateStoryTag(value, tagEntities as StoryEntity[], updatedKeywords));
};

export const setTimelineEventAction = (event: TimelineEvent) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setTimelineEvent(event));
};

export const fetchTimeline = (storyId: StoryId, page: { limit: number; offset: number }) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const timelineEvent = getState().storyEditor.ui.timelineEvent;
  const versionId = getState().storyEditor.story["story-version-id"];
  dispatch(setStoryValidationErrors({ inspector: {}, editor: {} }));
  fetchTimelineEvents(storyId, page).then((response) => {
    if (timelineEvent.id === 0) {
      const versionEvent = response.events.find((event) => event["content-version-id"] === versionId);
      dispatch(setTimelineEventAction(versionEvent || response.events[0]));
    }
    dispatch({ type: actions.APPEND_TO_TIMELINE, payload: response });
  });
};

export const addElementToCompositeStoryElement = (parentStoryElement: CompositeStoryElement) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const { textStoryElement, compositeElementTree } = appendTextStoryElementToComposite(parentStoryElement);
  dispatch(
    storyEditorAddStoryElement(
      textStoryElement,
      { ...parentStoryElement, ...compositeElementTree },
      StoryElementDirection.BOTTOM
    )
  );
};

const publishStory = (
  storyId: string,
  storyVersionId: string,
  transition: WorkflowActions,
  dispatch: ThunkDispatch<any, any, any>,
  publishAt: number | null
) => {
  if (transition === WorkflowActions.StorySchedule && !publishAt) {
    dispatch(setPublishInspectorValidationErrors(t("story-editor.schedule-publish-validation-error")));
    return;
  }

  dispatch(publishStoryInit());
  updateStoryStatus(storyId, { "story-version-id": storyVersionId, action: transition, "publish-at": publishAt })
    .then((response) => {
      const navigate = navigateFn(dispatch);
      const tabSlug = response && response["current-status"] === "draft" ? "open" : response!["current-status"];

      dispatch(updatePageReferer("storyEditor"));

      setTimeout(() => {
        transition === "story-publish"
          ? navigate(WORKSPACE_TABS_PATH, { tabSlug: "published" })
          : navigate(WORKSPACE_TABS_PATH, { tabSlug: tabSlug });
      }, 1500);

      transition === WorkflowActions.StoryPublish || transition === WorkflowActions.StoryCorrectionPublish
        ? dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: { message: t("story-editor.successful_story_publish"), action: null }
          })
        : dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: { message: t("story-editor.successful_story_schedule"), action: null }
          });
    })
    .catch((e) => {
      dispatch(publishStoryFailure());

      const errorInfo = handleUnexpectedError(dispatch, e);

      if (!errorInfo) return;

      const { errors } = errorInfo.error;

      if (errors) {
        if (errorInfo.errorCode === ErrorCodes.PublishTimeElapsed) {
          dispatch(notificationError(t("story-editor.schedule-publish-time-past")));
        }
        if (errorInfo.errorCode === ErrorCodes.InvalidEmbargoPublish) {
          dispatch(notificationError(t("story-editor.invalid-embargo-publish")));
        }
        const groupedErrors = groupValidationErrors(errors);
        if (groupedErrors.editor.cards && groupedErrors.editor.cards.code === "presence") {
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: { message: t("story-editor.atleast_one_card_required"), action: null }
          });
        } else {
          if (groupedErrors.editor.cards) {
            dispatch({
              type: NOTIFICATION_ERROR,
              payload: { message: t("story-editor.errors_in_cards"), action: null }
            });
          }
          if (get(groupedErrors.editor, ["hero-image", "metadata", "focus-point", "code"]) === "presence") {
            dispatch({
              type: NOTIFICATION_ERROR,
              payload: { message: t("story-editor.focusPoint") }
            });
          }
          dispatch(setStoryValidationErrors(groupedErrors));
          if (!isEmptyObject(groupedErrors.inspector)) {
            const navigate = navigateFn(dispatch);
            navigate(STORY_EDITOR_MANAGE_PATH, { id: storyId });
          } else if (!isEmptyObject(groupedErrors.alternativeInspector)) {
            navigate(STORY_EDITOR_ALTERNATIVES_PATH, { id: storyId })(dispatch);
          }
        }
        return;
      }
      return;
    });
};

export const publishOrSchedulePublishAction = (transition: WorkflowActions) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const story = getState().storyEditor.story as Story;
  const { "story-content-id": storyId, "story-version-id": storyVersionId, "publish-at": publishAt } = story;

  if (publishAt && isPast(publishAt)) {
    dispatch(notificationError(t("story-editor.schedule-publish-time-past")));
    return;
  }

  const isStoryModified = getState().storyEditor.ui.isStoryModified;

  if (transition) {
    if (isStoryModified) {
      dispatch(
        saveStoryAction((contentId: string, versionId: string) =>
          publishStory(contentId, versionId, transition, dispatch, publishAt)
        )
      );
    } else {
      publishStory(storyId, storyVersionId, transition, dispatch, publishAt);
    }
  } else {
    dispatch(setPublishInspectorValidationErrors(t("story-editor.please-select-option")));
  }
};

export const updateStatusAction = (storyId: StoryId, storyVersionId: StoryId, transition: WorkflowActions) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  if (
    transition === WorkflowActions.StoryPublish ||
    transition === WorkflowActions.StoryScheduleModify ||
    transition === WorkflowActions.StoryScheduleWithCollection
  ) {
    return navigate(STORY_EDITOR_PUBLISH, { id: storyId })(dispatch);
  }
  if (transition === WorkflowActions.StoryScheduleCancel) {
    dispatch({ type: actions.UPDATE_STORY_PUBLISH_AT, payload: { timestamp: null } });
  }
  const state = getState();
  const defaultParams = { "story-version-id": storyVersionId, action: transition };
  const asanaProject = state.storyEditor.app.asanaProject;
  const params =
    transition === "story-submit" && asanaProject
      ? { ...defaultParams, "asana-project-id": asanaProject["project-id"] }
      : defaultParams;

  if (
    state.features.isValidatorEnabled &&
    state.features.isSeoCheckMandatory &&
    transition === WorkflowActions.StorySubmit &&
    !state.storyEditor.ui.seoChecked
  ) {
    return navigate(STORY_EDITOR_PATH, { id: storyId }, { validator: true })(dispatch);
  }
  dispatch(setStoryTransitionStatus(true));
  updateStoryStatus(storyId, params)
    .then((response) => {
      dispatch(
        response &&
          updateStoryStatusAction(response["workflow-transitions"], response["current-status"], response["task-id"])
      );
      if (response!["current-status"] === StoryStatus.Published && transition === WorkflowActions.StoryClose) {
        navigate(WORKSPACE_TABS_PATH, { tabSlug: "published" })(dispatch);
        dispatch({
          type: NOTIFICATION_SUCCESS,
          payload: { message: t("story-editor.successful_live_blog_close"), action: null }
        });
      }
      if (response!["current-status"] === StoryStatus.Draft) {
        navigate(WORKSPACE_TABS_PATH, { tabSlug: "open" })(dispatch);
        dispatch({
          type: NOTIFICATION_SUCCESS,
          payload: { message: t("story-editor.successful_story_retract"), action: null }
        });
      }
      return;
    })
    .catch((e) => {
      const errorInfo = handleInvalidScheduleCancelError(storyId, dispatch, e);

      if (!errorInfo) return;

      const { errors } = errorInfo.error;
      const groupedErrors = groupValidationErrors(errors);
      if (groupedErrors.editor.cards && groupedErrors.editor.cards.code === "presence") {
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: { message: t("story-editor.atleast_one_card_required"), action: null }
        });
      } else if (
        groupedErrors.inspector["asana-project-id"] &&
        groupedErrors.inspector["asana-project-id"].code === "presence"
      ) {
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: { message: t("story-editor.inspector.please-choose-asana-project"), action: null }
        });
      } else {
        if (groupedErrors.editor.cards) {
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: { message: t("story-editor.errors_in_cards"), action: null }
          });
        }
        if (get(groupedErrors.editor, ["hero-image", "metadata", "focus-point", "code"]) === "presence") {
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: { message: t("story-editor.focusPoint") }
          });
        }
        dispatch(setStoryValidationErrors(groupedErrors));
        if (!isEmptyObject(groupedErrors.inspector)) {
          navigate(STORY_EDITOR_MANAGE_PATH, { id: storyId })(dispatch);
        } else if (!isEmptyObject(groupedErrors.alternativeInspector)) {
          navigate(STORY_EDITOR_ALTERNATIVES_PATH, { id: storyId })(dispatch);
        }
      }
    });
};
export const hasValidationError = (item: Image, index = 0) => {
  let errorsInMedia: Array<ValidationError> = [];
  if (item.metadata["file-name"] && item.metadata["file-name"].length > 500) {
    errorsInMedia.push({ id: index, fileName: true, message: t("mediaLibrary.file_name_error") });
  } else if (!item.metadata["file-name"] || item.metadata["file-name"].length < 1) {
    errorsInMedia.push({ id: index, fileName: true, message: t("mediaLibrary.file_name_empty_error") });
  }
  return errorsInMedia;
};

export const setSelectedImageAction = (
  media: AnyImage[],
  mediaAs: { type: string; subtype?: string },
  imageId?: ClientId
) => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
  const navigate = navigateFn(dispatch);
  let hasErrors: Array<ValidationError> = [];
  const story = getState().storyEditor.story;
  dispatch({ type: actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT });
  let image: ImageOrNewImage;
  const selectedMedia = media[0]; //we only want one image but its array of object
  const isNewImage = selectedMedia.hasOwnProperty("temp-image-key");
  if (isNewImage) {
    selectedMedia.metadata["file-name"] = sanitizeFileName(selectedMedia.metadata["file-name"] || "");
    hasErrors = hasValidationError(selectedMedia);
  }
  if (hasErrors.length > 0) {
    dispatch({ type: actions.IMAGE_UPDATE_ERROR, payload: hasErrors });
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("mediaLibrary.unable_processing_image", { count: hasErrors.length }) }
    });
  } else {
    getMetadataWithDimensions(selectedMedia).then((metadata) => {
      if (isNewImage) {
        image = {
          ...selectedMedia,
          caption: selectedMedia.caption || "",
          attribution: selectedMedia.attribution || "",
          "alt-text": selectedMedia["alt-text"] || "",
          metadata: metadata,
          "temp-key": selectedMedia["temp-image-key"]
        };
        delete image["temp-image-key"];
        delete image["success"];
      } else {
        image = {
          ...selectedMedia,
          metadata: metadata,
          caption: selectedMedia.caption || "",
          attribution: selectedMedia.attribution || "",
          "alt-text": selectedMedia["alt-text"] || ""
        } as Image;
      }

      if (mediaAs.type === "hero-image") {
        dispatch(setSelectedHeroImage(image));
      } else if (mediaAs.type === "image-element") {
        imageId && dispatch(updateImageElement(imageId, image));
      } else if (mediaAs.type === "alternative-hero-image") {
        dispatch(updateAlternative(mediaAs.subtype || "home", "hero-image", image));
      }

      const path = mediaAs.type === "alternative-hero-image" ? STORY_EDITOR_ALTERNATIVES_PATH : STORY_EDITOR_PATH;

      navigate(path, { id: story["story-content-id"], imageId: imageId || "new" });
    });
  }
};

export const editImageAction = (storyId: StoryId, subtype: string, image: Image, path: string) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const navigate = navigateFn(dispatch);
  dispatch(setImageForInspector(image));
  navigate(path, { id: storyId, subtype });
};

export const editInspectorImageAction = (
  storyId: StoryId,
  image: Image,
  path: string,
  imageId?: string,
  elementType: string = "singleImage"
) => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const navigate = navigateFn(dispatch);
  dispatch({ type: actions.SET_IMAGE_FOR_INSPECTOR, payload: { image } });
  navigate(path, {
    id: storyId || getState().storyEditor.story["story-content-id"],
    imageId: imageId || "new",
    elementType
  });
};

export const uploadCardImageAction = (storyId: StoryId, cardId: CardId, image: Image, imageEdit = false) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const navigate = navigateFn(dispatch);
  dispatch(cardShareUpdate("image", image, cardId));
  if (!imageEdit) {
    navigate(STORY_EDITOR_CARD_SHARE_IMAGE_PATH, { id: storyId, cardId });
  }
};

export const deleteCardImageAction = (cardId: CardId) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cardShareImageDelete(cardId));
};

export const updateImageElement = (id: ClientId, changes: any) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyElementId = getElementIdFromClientId(id, getState().storyEditor.story["story-elements"]);
  storyElementId && dispatch(updateImageElementAction(storyElementId, changes));
};

export const openGalleryInspector = (e: MouseEvent, imageId: ImageId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  e.stopPropagation();
  const navigate = navigateFn(dispatch);
  const story = getState().storyEditor.story;

  if (imageId) {
    return navigate(STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH, { id: story["story-content-id"], imageId });
  }
  return;
};

export const openImageGalleryElementMediaInspector = (e: Event, storyElementClientId: string | number) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  e.stopPropagation();
  const navigate = navigateFn(dispatch);
  const story = getState().storyEditor.story;

  if (storyElementClientId) {
    return navigate(STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH, {
      id: story["story-content-id"],
      storyElementClientId
    });
  }
  return;
};

export const openOEmbedVideoInspector = (e: MouseEvent, storyElementClientId: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  e.stopPropagation();

  const videoIntegrationConfig = getState().config.videoOEmbedConfig;
  if (!videoIntegrationConfig || isEmpty(videoIntegrationConfig.providers)) {
    dispatch({
      type: NOTIFICATION_INFO,
      payload: { message: t("story-editor.video-oembed-selector.providers-not-configured-error"), action: null }
    });
    return;
  }

  const story = getState().storyEditor.story;
  const navigate = navigateFn(dispatch);
  if (storyElementClientId) {
    return navigate(STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH, {
      storyElementClientId,
      id: story["story-content-id"]
    });
  }
  return;
};

export const closeOEmbedVideoInspector = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const story = getState().storyEditor.story;
  const navigate = navigateFn(dispatch);
  navigate(STORY_EDITOR_PATH, {
    id: story["story-content-id"]
  });
  dispatch(videoOEmbedSearchReset());
};

export const searchOEmbedVideo = (
  provider: string,
  term: string,
  direction: string | null,
  opts: VideoOembedSearchPages = INITIAL_VIDEO_OEMBED_SEARCH_PAGES
) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  if (direction === "input") return;
  const searchError = ({ message }: { message?: string }) =>
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: message || t("story-editor.video-oembed-selector.search-error"), action: null }
    });

  dispatch(videoOEmbedSearchTermChange(term));
  try {
    const searchOpts = {
      ...opts,
      token: direction === "next" ? opts.token.next : opts.token.prev
    };
    const searchResponse = await pralineSearchDebounced(provider, term, searchOpts);
    if (!searchResponse || searchResponse.error) {
      const error = searchResponse && searchResponse.error;
      searchError(error || {});
      return;
    }

    const {
      page,
      total = 0,
      items: videos = [],
      "next-page-token": nextToken = null,
      "prev-page-token": prevToken = null
    } = searchResponse;

    dispatch(
      videoOEmbedSearchSuccess({
        page,
        total,
        videos,
        token: {
          prev: prevToken,
          next: nextToken
        }
      })
    );
  } catch (e) {
    searchError({});
    return;
  }
};

export const getPralineOEmbedData = (provider: string, video: PralineVideo, storyElementClientId: string) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyElementID: any = getElementIdFromClientId(
    storyElementClientId,
    getState().storyEditor.story["story-elements"]
  );

  storyElementID && dispatch(toggleStoryElementLoading(storyElementID));
  const playerId = get(getState(), ["config", provider, "player-id"], "");
  const getOembedError = ({ message }: { message?: string }) =>
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: message || t("story-editor.video-oembed-selector.get-oembed-error"), action: null }
    });
  try {
    const { url: videoUrl = "", id: videoId = "" } = video;
    const oEmbedResponse = await getOEmbed(provider, videoId, playerId);
    if (!oEmbedResponse || oEmbedResponse.error) {
      const error = oEmbedResponse && oEmbedResponse.error;
      getOembedError(error || {});
      return;
    }

    const {
      "oembed-src": embedUrl,
      html: embedHTML,
      "provider-name": providerName,
      "oembed-type": oembedType
    } = oEmbedResponse;
    const knownProvider = guessEmbedProvider(embedUrl || embedHTML);
    const isJSEmbed = knownProvider === EMBEDS.JSEMBED;
    const isDailymotionVideo =
      EMBEDS.DAILYMOTION_VIDEO === knownProvider || EMBEDS.DAILYMOTION_EMBED_SCRIPT === knownProvider;
    const elementType = isDailymotionVideo ? EMBEDS.JSEMBED : knownProvider;
    const usePralineEmbed = isJSEmbed || isDailymotionVideo;

    const oEmbedData = {
      provider: elementType,
      ...(!usePralineEmbed && {
        url: videoUrl,
        "embed-url": embedUrl
      }),
      ...(usePralineEmbed && {
        subtype: providerName.toLowerCase(),
        "embed-js": base64Encode(embedHTML)
      }),
      ...(isDailymotionVideo && {
        subtype: oembedType,
        metadata: {
          "video-id": videoId,
          "dailymotion-url": videoUrl,
          provider: providerName.toLowerCase(),
          "player-id": playerId
        }
      })
    };

    storyElementID && dispatch(toggleStoryElementLoading(storyElementID));
    dispatch(setOembedUrl(storyElementID, oEmbedData));
    dispatch(closeOEmbedVideoInspector());
  } catch (e) {
    getOembedError({});
    return;
  }
};

export const addNewElementAction = (
  elementType: StoryElementType | StoryElementSubType<StoryElementType>,
  emptyCardId: CardId,
  currentElement: StoryElement | null,
  elementAt: StoryElementDirection,
  subElementType?: string | undefined
) => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const cardId = currentElement ? currentElement["card-id"] : emptyCardId;
  const currentCard = getState().storyEditor.story.cards[cardId];
  const newElement = newStoryElement(currentCard, elementType, subElementType);
  dispatch(storyEditorAddStoryElement(newElement as StoryElement, currentElement, elementAt));
  if (elementType === "references") {
    dispatch(addElementToCompositeStoryElement(newElement as CompositeStoryElement));
  }
};

export const splitElementAction: any = (
  elementType: StoryElementType | StoryElementSubType<StoryElementType>,
  currentElement: Node<Schema>,
  elementAt: number,
  subElementType?: string | undefined
) => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const currentSEElement =
    currentElement &&
    currentElement["attrs"] &&
    getState().storyEditor.story["story-elements"][currentElement["attrs"]["id"]];
  const cardId = currentSEElement && currentSEElement["card-id"];
  const currentCard = getState().storyEditor.story.cards[cardId];
  const newElement = newStoryElement(currentCard, elementType, subElementType);
  const newTextElement = newStoryElement(currentCard, StoryElementType.Text);
  dispatch(
    storyEditorSplitStoryElement(
      newElement as StoryElement,
      currentSEElement as StoryElement,
      newTextElement as StoryElement,
      elementAt
    )
  );
  // Reapply editor state so that cursor position will be updated after the new story element is rendered in DOM
  dispatch(reapplyEditorState());
  if (elementType === "references") {
    dispatch(addElementToCompositeStoryElement(newElement as CompositeStoryElement));
  }
};

export const deleteStoryElementAction = (storyElement: StoryElement | CompositeStoryElement | ChildStoryElement) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(storyEditorDeleteStoryElement(storyElement));
};

export const deleteCardAction = (cardId: CardId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(deleteStoryEditorCard(cardId));
};

export const shareCardAction = (cardId: CardId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const navigate = navigateFn(dispatch);
  const storyId = getState().storyEditor.story["story-content-id"];

  navigate(STORY_EDITOR_CARD_SHARE, { id: storyId, cardId });
};

export const updateRouteDataAction = (path: string, params: any) => (dispatch: ThunkDispatch<any, any, any>) => {
  const data = generateInspectorDataByRoute(path, params);
  dispatch(updateInspectorData(data));
};

const saveStoryForBlockingAutoSaveAction = (callback: Function) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const state = getState();
  let story = state.storyEditor.story;
  const isHeadlineEmpty = isEmpty(story.headline);
  const isLiveBlog = story["story-template"] === "live-blog";

  if (isHeadlineEmpty && isLiveBlog) {
    dispatch(notificationError(t("story-editor.header-card.please-add-headline")));
    return;
  }

  if (isHeadlineEmpty) {
    const untitledHeadline = generateUntitledHeadline();
    dispatch(updateStory("headline", untitledHeadline));
    story = { ...story, headline: untitledHeadline };
  }

  try {
    dispatch(startSavingStory());
    story = annotateStoryWithAuthorInfo(state, story);
    dispatch(setIsStoryModifiedState(false));
    try {
      checkDuplicateStoryElements(story as Story);
      const response = await saveStory(story["story-content-id"], story as Story);
      handleSaveStorySuccess(dispatch, state, response);
    } catch (e) {
      handleSaveStoryError(dispatch, e, story);
    } finally {
      callback && callback();
    }
  } catch (e) {
    w.newrelic.noticeError(e, {
      errorType: "save_error",
      errorDescription: "Stuck in saving mode",
      errorInfo: JSON.stringify({ storyEditorUiState: state.storyEditor.ui }),
      reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
    });
    handleSaveStoryError(dispatch, e, story);
  }
};

export const cancelPublishAndSaveStoryForBlockingAutoSaveAction = (callback: Function) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const state = getState();
  let story = state.storyEditor.story;

  if (story["status"] === "scheduled") {
    const storyId = story["story-content-id"];
    const storyVersionId = story["story-version-id"];
    const transition = WorkflowActions.StoryScheduleCancel;
    const params = { "story-version-id": storyVersionId, action: transition };

    dispatch({ type: actions.UPDATE_STORY_PUBLISH_AT, payload: { timestamp: null } });

    updateStoryStatus(storyId, params)
      .then((response) => {
        dispatch(
          response &&
            updateStoryStatusAction(response["workflow-transitions"], response["current-status"], response["task-id"])
        );

        dispatch(saveStoryForBlockingAutoSaveAction(callback));

        dispatch({
          type: NOTIFICATION_INFO,
          payload: { message: t("story-editor.story-schedule-cancelled-as-story-was-saved"), action: null }
        });
      })
      .catch((e) => {
        handleUnexpectedError(dispatch, e);
      });
  } else {
    dispatch(saveStoryForBlockingAutoSaveAction(callback));
  }
};

function cleanHeroImageAttributes(story) {
  const heroImageCaption = story["hero-image"] && story["hero-image"].caption;
  const heroImageAttribution = story["hero-image"] && story["hero-image"].attribution;
  const heroImageAltText = story["hero-image"] && story["hero-image"]["alt-text"];

  if (heroImageCaption && getHtmlWordCount(heroImageCaption) === 0) {
    story["hero-image"].caption = "";
  }
  if (heroImageAttribution && getHtmlWordCount(heroImageAttribution) === 0) {
    story["hero-image"].attribution = "";
  }
  if (heroImageAltText && getHtmlWordCount(heroImageAltText) === 0) {
    story["hero-image"]["alt-text"] = "";
  }
  return story;
}

export const saveStoryAction = (callback?: Function, action?: any) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const state = getState();
  const transition = action ? action : state.storyEditor.ui.changeStatusAction;

  let story = state.storyEditor.story,
    isStoryModified = state.storyEditor.ui.isStoryModified,
    emptyHeadline = generateUntitledHeadline();

  if (isEmpty(story.headline)) {
    if (story["story-template"] === "live-blog") {
      dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("story-editor.header-card.please-add-headline") } });
      return;
    }
    dispatch({ type: actions.UPDATE_STORY, payload: { key: "headline", value: emptyHeadline } });
  }

  if (isStoryModified) {
    try {
      dispatch(startSavingStory());
      if (story["story-template"] === "live-blog" && story["status"] === "published") {
        dispatch(removeEmptyTextElementAction(story as StoryWithoutSlug));
        story = getState().storyEditor.story;
      }

      story = annotateStoryWithAuthorInfo(state, story);

      const storyWithHeadline = isEmpty(story.headline) ? { ...story, headline: emptyHeadline } : story;
      const storyWithoutSlug = removeKeyFromObject("slug", storyWithHeadline);
      dispatch(setIsStoryModifiedState(false));
      if (story["story-content-id"] === "new") {
        saveNewStory(story["story-content-id"], cleanHeroImageAttributes(storyWithoutSlug) as UnsavedStory).then(
          (response: SaveResponse) => {
            const currentPath = state.router.location.pathname;
            const newStory = handleSaveStorySuccess(dispatch, state, response);
            const storyEditorPath = currentPath.replace("new", newStory.id);
            dispatch(replace(route(storyEditorPath)));
            if (callback) {
              callback(story["story-content-id"], response["story-version"].id, transition);
            }
          },
          (error) => {
            handleSaveStoryError(dispatch, error, story);
          }
        );
      } else {
        let modifiedStory = cleanHeroImageAttributes(storyWithoutSlug);
        checkDuplicateStoryElements(modifiedStory);
        saveStory(story["story-content-id"], modifiedStory as Story).then(
          (response: SaveResponse) => {
            handleSaveStorySuccess(dispatch, state, response);
            if (callback) {
              callback(story["story-content-id"], response["story-version"].id, transition);
            }
          },
          (error) => {
            handleSaveStoryError(dispatch, error, story);
          }
        );
      }
    } catch (e) {
      w.newrelic.noticeError(e, {
        errorType: "save_error",
        errorDescription: "Stuck in saving mode",
        errorInfo: JSON.stringify({ storyEditorUiState: state.storyEditor.ui }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
      handleSaveStoryError(dispatch, e, story);
    }
  } else {
    if (callback) {
      callback(story["story-content-id"], story["story-version-id"], transition);
    }
  }
};

export const cancelPublishAndSaveAction = (callback?: Function, action?: any) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const story = getState().storyEditor.story;
  const storyId = story["story-content-id"];
  const storyVersionId = story["story-version-id"];
  const transition = WorkflowActions.StoryScheduleCancel;
  const params = { "story-version-id": storyVersionId, action: transition };
  dispatch(setStoryTransitionStatus(true));
  dispatch({ type: actions.UPDATE_STORY_PUBLISH_AT, payload: { timestamp: null } });
  updateStoryStatus(storyId, params)
    .then((response) => {
      dispatch(
        response &&
          updateStoryStatusAction(response["workflow-transitions"], response["current-status"], response["task-id"])
      );
      dispatch(saveStoryAction());
      dispatch({
        type: NOTIFICATION_INFO,
        payload: { message: t("story-editor.story-schedule-cancelled-as-story-was-saved"), action: null }
      });
    })
    .catch((e) => {
      handleInvalidScheduleCancelError(storyId, dispatch, e);
    });
};

export const restoreStoryVersionAction = (storyId: StoryId, storyVersionId: StoryId) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const navigate = navigateFn(dispatch);
  restoreStory(storyId, storyVersionId).then(
    (response) => {
      // Resetting the read only story version so that it does not appear in the relaoded story
      dispatch(resetTimeline());
      dispatch(closeStoryRestoreModal());
      navigate(STORY_EDITOR_PATH, { id: storyId });
    },
    () => {
      dispatch(closeStoryRestoreModal());
      dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("story-editor.restore-error"), action: null } });
    }
  );
};

export const closeTimelineAction = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const navigate = navigateFn(dispatch);
  const storyId = getState().storyEditor.story["story-content-id"];
  dispatch(resetTimeline());
  navigate(STORY_EDITOR_PATH, { id: storyId });
};

export const updateEmbargoAction = (timestamp: number | null) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const navigate = navigateFn(dispatch);
  const storyId = getState().storyEditor.story["story-content-id"];
  dispatch(updateEmbargo(timestamp));
  navigate(STORY_EDITOR_PATH, { id: storyId });
};

export const fetchPollDetailsAction = (storyElementID: StoryElementId, pollId: PolltypeId): any => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(fetchOpinionPoll());
  dispatch(setOpinionPollInit(storyElementID));
  getPollDetails(pollId).then((response) => {
    dispatch(setOpinionPoll(storyElementID, response.poll));
    dispatch(setOpinionPollSuccess(storyElementID));
  });
};

export const updatePollDetails = (storyElementId: StoryElementId, key: string, value: any) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(updateOpinionPoll(storyElementId, key, value));
};

export const savePollAction = (storyElementID: StoryElementId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const poll = getState().storyEditor.ui.storyElements[storyElementID].poll;
  const story = getState().storyEditor.story as Story;
  if (poll.id) {
    savePoll(poll.id, { changes: poll })
      .then((response) => {
        dispatch(removeStoryElementError(storyElementID));
        dispatch(setOpinionPollInit(storyElementID));
        getPollDetails(response.poll.id).then((res) => {
          dispatch(setOpinionPollId(storyElementID, res.poll.id));
          dispatch(setOpinionPoll(storyElementID, res.poll));
          dispatch(setOpinionPollSuccess(storyElementID));
          dispatch(navigate(STORY_EDITOR_PATH, { id: story["story-content-id"] }));
        });
      })
      .catch((error: any) => {
        const errorResponse = JSON.parse(error.text).errors;
        dispatch(setStoryElementError(storyElementID, opinionPollErrors(errorResponse)));
      });
  } else {
    createPoll({ poll })
      .then((response) => {
        dispatch(removeStoryElementError(storyElementID));
        dispatch(setOpinionPollInit(storyElementID));
        getPollDetails(response.poll.id).then((res) => {
          dispatch(setOpinionPollId(storyElementID, res.poll.id));
          dispatch(setOpinionPoll(storyElementID, res.poll));
          dispatch(setOpinionPollSuccess(storyElementID));
          dispatch(navigate(STORY_EDITOR_PATH, { id: story["story-content-id"] }));
        });
      })
      .catch((error: any) => {
        const errorResponse = JSON.parse(error.text).errors;
        if (errorResponse) {
          dispatch(setStoryElementError(storyElementID, opinionPollErrors(errorResponse)));
        }
      });
  }
};

export const createPollAction = (storyId: StoryId, storyElementId: StoryElementId) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const newPoll = newOpinionPoll();
  dispatch(setOpinionPoll(storyElementId, newPoll));
  dispatch(setImageForInspector(newPoll["hero-image"]));
  dispatch(navigate(STORY_EDITOR_POLL_EDIT, { id: storyId, storyElementId }));
};

export const editPollAction = (storyId: StoryId, storyElementId: StoryElementId, image: Image) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(setImageForInspector(image));
  dispatch(navigate(STORY_EDITOR_POLL_EDIT, { id: storyId, storyElementId }));
};

export const setPollImage = (storyId: StoryId, storyElementId: StoryElementId, images: Array<Image>) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const image = images[0];
  const imageKey = image && image.key ? { "s3-key": image.key } : { "temporary-key": image["temporary-key"] };
  const updatedImageForPoll = {
    ...imageKey,
    metadata: image.metadata,
    attribution: image.attribution,
    caption: image.caption,
    url: image.url
  };
  dispatch(updatePollDetails(storyElementId, "hero-image", updatedImageForPoll));
};

export const addNewPollImage = (files: File[], opts: { [key: string]: any }) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  if (files.length === 0) {
    return;
  }
  dispatch(updateOpinionPollImageUploadStatus(true));

  const errorMessage = () =>
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("story-editor.inspector.error_poll_image") } });

  uploadImage(files, updateOpinionPollImageUploadStatus, setPollImage, errorMessage, dispatch, opts);
};
export const setMultiSelectedMediaInspectorAction = (storyElementClientId: ClientId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyId = getState().storyEditor.story["story-content-id"];
  const selectedMedia: any = getState().mediaLibrary.ui.mediaForMultiSelect;

  if (!selectedMedia || !selectedMedia.length) {
    dispatch(navigate(STORY_EDITOR_PATH, { id: storyId }));
    return;
  }
  const transformedSelectedImages = selectedMedia.map((media) => transformMediaImageToStoryTypeImage(media));
  dispatch(
    editInspectorImageAction(
      storyId,
      transformedSelectedImages,
      STORY_EDITOR_IMAGE_INSPECTOR_PATH,
      storyElementClientId,
      "multiImage"
    )
  );
};

export const addMediatoGalleryElementAction = (storyElementClientId: ClientId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyElementId = getElementIdFromClientId(storyElementClientId, getState().storyEditor.story["story-elements"]);

  if (storyElementId) {
    const storyId = getState().storyEditor.story["story-content-id"];
    const selectedMedia = getState().mediaLibrary.ui.mediaForMultiSelect;
    const imageGalleryElement = getState().storyEditor.story["story-elements"][storyElementId] as CompositeStoryElement;
    const transformedSelectedImages = selectedMedia.map((media: any) => transformMediaImageToStoryTypeImage(media));

    const { imageStoryElements, imageGalleryTree } = addImagesToImageGalleryElement(
      imageGalleryElement,
      transformedSelectedImages
    );

    imageGalleryTree.map((seId) => {
      const element = getState().storyEditor.story["story-elements"][storyElementId] as CompositeStoryElement;
      const parentStoryElement = { ...element, tree: [...element.tree, seId] };
      dispatch(storyEditorAddStoryElement(imageStoryElements[seId], parentStoryElement, StoryElementDirection.BOTTOM));
      return null;
    });

    dispatch(clearMediaForMultiSelectAction());
    dispatch(navigate(STORY_EDITOR_PATH, { id: storyId }));
  }
};

const transformMediaImageToStoryTypeImage = (media: any) => {
  //if it is a uploaded image
  if (!media.instances) {
    return {
      url: media.image.url,
      "extracted-data": media.image["extracted-data"],
      "temp-key": media.image["temp-image-key"],
      key: media.image.key,
      caption: media.image.caption || "",
      attribution: media.image.attribution || "",
      "alt-text": media.image["alt-text"] || "",
      hyperlink: media.image.hyperlink || "",
      metadata: { ...media.image.metadata }
    };
  } else {
    return {
      url: media.image.url,
      key: media.image.key,
      "extracted-data": media.image["extracted-data"],
      caption: media.instances[0].caption || "",
      attribution: media.instances[0].attribution || "",
      "alt-text": media.instances[0]["alt-text"] || "",
      "uploaded-at": media.image["uploaded-at"] || "",
      hyperlink: media.instances[0].hyperlink || "",
      metadata: { ...media.image.metadata }
    };
  }
};

// TODO type Media and Image
export const setMediaForMultiSelectAction = (media: any, storyElementClientId: ClientId, storyId: StoryId) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  let hasErrors: Array<ValidationError> = [];
  dispatch({ type: actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT });
  const sanitizedMedia = media.map((image: any, index: number) => {
    const isNewImage = image.hasOwnProperty("temp-image-key");
    if (isNewImage) {
      image.metadata["file-name"] = sanitizeFileName(image.metadata["file-name"] || "");
      hasErrors = [...hasErrors, ...hasValidationError(image, index)];
    }
    return image;
  });
  if (hasErrors.length > 0) {
    dispatch({ type: actions.IMAGE_UPDATE_ERROR, payload: hasErrors });
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("mediaLibrary.unable_processing_image", { count: hasErrors.length }) }
    });
  } else {
    const transformedMedia = sanitizedMedia.map((image: any, index: number) => {
      return {
        image: image
      };
    });
    dispatch(clearMediaForMultiSelectAction());
    transformedMedia.map((image: any) =>
      dispatch({ type: MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT, payload: { media: image } })
    );
    dispatch(addMediatoGalleryElementAction(storyElementClientId));
  }
};

export const updateStorySearchTermAction = (value: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateStorySearchTerm(value));
  const { stories } = await fetchStoryForImportDebouce(value);
  dispatch(setStoryList(stories));
};

export const setSelectedStoryAction = (storyId: StoryId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const { story } = await getStory(storyId, {});
  dispatch(setSelectedStory(story));
};

export const updateImportCardIdAction = (cardId: CardId) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateImportCardId(cardId));
};

export const resetImportCardDisplayAction = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(resetImportCardDisplay());
};

export const importCardAction = (cardId: CardId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyId = getState().storyEditor.story["story-content-id"];
  const importStory = getState().storyEditor.app.importCard.selectedStory;
  const { newCard, newStoryElements } = convertImportDataToNew(importStory as Story, cardId);
  dispatch(importCard(newCard, newStoryElements as Array<StoryElement | CompositeStoryElement | ChildStoryElement>));
  dispatch(navigate(STORY_EDITOR_PATH, { id: storyId }));
  dispatch(resetImportCard());
};

export const toggleStoryCleanUpConfirmationAction = (action?: any) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(toggleStoryCleanUpConfirmation(action ? action : null));
};

export const removeEmptyTextElementAction = (story: StoryWithoutSlug | AnyStory, callback?: Function) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const allElements = Object.values(story["story-elements"]);
  const filterEmpty = (element: StoryElement) =>
    story["story-template"] === "live-blog"
      ? element.type === "title" && element.subtype === null && !element.text
      : element.type === "text" && element.subtype === null && element.text === "<p></p>";

  const emptyTextElements = allElements.filter(filterEmpty);

  emptyTextElements.forEach((storyElement) => {
    dispatch({ type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT, payload: { storyElement } });
  });
  const updatedStory = getState().storyEditor.story;
  const allCards = Object.values(updatedStory.cards);
  allCards.forEach((card) => {
    if (card.tree.length === 0) {
      dispatch(deleteStoryEditorCard(card.id));
    }
  });
  if (callback) {
    callback();
  }
};
export const resetStoryEditorStateAction = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(resetStoryEditorState());
};
export const setBannerAction = (banner: {} | null) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setBanner(banner));
};

export const getPlagiarismReport = (id: StoryId, versionId: StoryId) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    dispatch(plagiarismCheckInit());
    dispatch(navigate(STORY_EDITOR_PLAGIARISM_REPORT, { id }));
    const { result: plagiarismReport } = await generatePlagiarismReport(id, versionId);
    dispatch(plagiarismCheckSuccess(plagiarismReport["message"], plagiarismReport["download-url"]));
  } catch (error) {
    const errorMessage = JSON.parse(error.text);
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: errorMessage.error, action: null } });
    dispatch(plagiarismCheckFailure(errorMessage.error));
    dispatch(navigate(STORY_EDITOR_PATH, { id }));
  }
};

export const updateSelectedPlaceAction = (place: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateSelectedPlace(place));
};

export const getStoryFieldSuggestion = (
  story: Story | UnsavedStory,
  field: AllowedStoryFields,
  maxLength: number,
  count: number
) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
): Promise<string[] | null> => {
  try {
    const suggestion = await getSuggestion(story, field, maxLength, getWebsiteLanguage(getState()), count);
    return suggestion[field];
  } catch (error) {
    dispatch(notificationError(t("story-editor.generate-field-suggestion-failure")));
    return null;
  }
};

export const paraphraseTextSelection = (textToParaphrase: string, selection: { from: number; to: number }) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  try {
    dispatch(setTextParaphrasingStatus(true));
    const paraphraseResponse = await paraphraseText(textToParaphrase, getWebsiteLanguage(getState()));
    const editorState = getState().storyEditor.editorState as EditorState;
    const isTextSelectionChanged = editorState.doc.textBetween(selection.from, selection.to) !== textToParaphrase;
    if (isTextSelectionChanged) {
      dispatch(notificationError(t("story-editor.paraphrase-text.text-selection-changed")));
    } else {
      const paraphrasedText = paraphraseResponse["paraphrased-text"];
      const tr = editorState.tr;
      const textNode = schema.text(paraphrasedText);
      tr.replaceWith(selection.from, selection.to, textNode);
      dispatch(setEditorState(editorState.apply(tr)));
    }
  } catch (error) {
    dispatch(notificationError(t("story-editor.paraphrase-text.failure")));
  } finally {
    dispatch(setTextParaphrasingStatus(false));
  }
};

export const setStoryElementText = (
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  text: string
) => async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
  const editorState = getState().storyEditor.editorState as EditorState;
  // Find element with client id since story element id will change after first save
  const elementNP = findElementWithClientIdNP(editorState, storyElement);
  const cursorPosition = findFirstEditableCursorPosition(elementNP);
  const initialTxn = editorState.tr;
  if (elementNP && cursorPosition && text && text.length > 0) {
    // Place cursor in the text element
    const afterCursorPlaceTxn = setTextSelection(initialTxn, editorState, cursorPosition);
    let updatedEditorState = editorState.apply(afterCursorPlaceTxn);
    // Select all text in the element
    const afterSelectTxn = selectElementText(updatedEditorState);
    // Delete selection
    const afterDeleteTxn = afterSelectTxn.deleteSelection();
    // Insert text at the start of story element
    afterDeleteTxn.insertText(text, cursorPosition);
    updatedEditorState = updatedEditorState.apply(afterDeleteTxn);
    const afterTextInsertTxn = updatedEditorState.tr;
    // Place the cursor after the text
    setTextSelection(afterTextInsertTxn, updatedEditorState, cursorPosition + text.length);
    dispatch(setEditorState(updatedEditorState.apply(afterTextInsertTxn)));
  }
};

export const clearStoryEditorMediaAction = () => (dispatch) => {
  dispatch({ type: actions.STORY_EDITOR_CLEAR_MEDIA });
};
